import {Component, Inject, LOCALE_ID} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {SidenavToggleService} from "../../services/sidenav-toggle.service";
import {Observable} from "rxjs";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-sidenav',
    imports: [CommonModule, MatIconModule, MatButtonModule, MatListModule, RouterLink, RouterLinkActive],
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  isLogin$: Observable<boolean>;
  constructor(private authService: AuthService,
              private sidenavToggleService: SidenavToggleService,
              @Inject(LOCALE_ID) public localeId: string) {
    this.isLogin$ = this.authService.isAuthenticated$;
  }
  toogle(): void {
    this.sidenavToggleService.toogleEvent.emit();
  }
}
