import {Component, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDrawer, MatSidenavModule} from "@angular/material/sidenav";
import {SidenavToggleService} from "../../services/sidenav-toggle.service";
import {SidenavComponent} from "../sidenav/sidenav.component";
import {RouterOutlet} from "@angular/router";
import {NavbarComponent} from "../navbar/navbar.component";
import {FooterComponent} from "../footer/footer.component";
import {ChatBubbleComponent} from "../chat-bubble/chat-bubble.component";

@Component({
    selector: 'app-layout',
    imports: [CommonModule, MatSidenavModule, SidenavComponent, RouterOutlet, NavbarComponent, FooterComponent, ChatBubbleComponent],
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer|undefined;

  isDrawerOpen = false;

  constructor(private sidenavToggleService: SidenavToggleService) { }

  ngOnInit(): void {
    this.sidenavToggleService.toogleEvent.subscribe(() => this.drawer?.toggle());

    this.drawer?.openedChange.subscribe((value: boolean) => this.isDrawerOpen = value);
  }

  backdropClicked(): void {
    if (this.isDrawerOpen) {
      this.drawer?.toggle();
    }
  }

}
