import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {Router, RouterEvent, RouterOutlet} from '@angular/router';
import {filter} from "rxjs";
import {SwUpdateService} from "./shared/services/sw-update.service";

@Component({
    selector: 'procell-repairs',
    standalone: true,
    imports: [CommonModule, RouterOutlet],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent  implements  OnInit{

 // isLogin$: Observable<boolean>;

  appUpdate = false;
  constructor(@Inject(PLATFORM_ID) private platformId: object|any,
              private swUpdateService: SwUpdateService,
              private router: Router) {
    if (isPlatformBrowser(this.platformId)) {
      const elemAppShell = document.querySelector('#app-shell');
      elemAppShell?.parentNode?.removeChild(elemAppShell);

      const stylesAppShellEl = document.querySelector('#style-app-shell');
      stylesAppShellEl?.parentNode?.removeChild(stylesAppShellEl);
    }
  }

  ngOnInit() {

    this.swUpdateService.appUpdate$.subscribe(update => this.appUpdate = update);

    this.router.events.pipe(
      filter((e: Event|any): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      if (this.appUpdate) {
        if (isPlatformBrowser(this.platformId)) {
          document.location.reload();
        }
      }
    });
  }
}
