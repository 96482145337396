import {ApplicationConfig, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, provideRouter, RouterStateSnapshot, withComponentInputBinding} from '@angular/router';

import { routes } from './app.routes';
import {provideNoopAnimations} from "@angular/platform-browser/animations";
import {provideClientHydration} from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch } from "@angular/common/http";
import {provideServiceWorker} from "@angular/service-worker";
import {HttpErrorHandlerInterceptor} from "./shared/http-interceptors/http-error-handler.interceptor";
import {LocaleQueryInterceptor} from "./shared/http-interceptors/locale-query.interceptor";
import {IMAGE_LOADER, ImageLoaderConfig, PRECONNECT_CHECK_BLOCKLIST} from "@angular/common";
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from "@abacritt/angularx-social-login";
import {environment} from "../environments/environment";

export const appConfig: ApplicationConfig = {
  providers: [
    {
        provide: { HTTP_INTERCEPTORS },
        useClass: HttpErrorHandlerInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LocaleQueryInterceptor,
        multi: true,
    },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      {
        window.location.href = (route.data as any).externalUrl;
      }
    },
    { provide: PRECONNECT_CHECK_BLOCKLIST, useValue: 'https://d1s5v1wclvrq5b.cloudfront.net' },
    {
        provide: IMAGE_LOADER,
        useValue: (config: ImageLoaderConfig) => {
          if(config && config.width !== undefined) {
            let newW = config.width > 800 ? 800 : config.width;
            //https://d21zicgaij1wrg.cloudfront.net/filters:format(webp)/fit-in/300x0/device-img/apple_iphone14_pro_max_5.jpg
            return `https://images.procellrepair.com/filters:format(webp)/fit-in/${newW}x0/${config.src}`;
          } else {
            return `https://images.procellrepair.com/${config.src}`;
          }
        }
    },
    {
        provide: 'SocialAuthServiceConfig',
        useValue: {
            autoLogin: false,
            providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider(environment.googleClientId)
                },
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider(environment.facebookClientId)
                }
            ],
            onError: (err) => {
                console.error(err);
            }
        } as SocialAuthServiceConfig,
    },
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideNoopAnimations(),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
]
};
