<mat-drawer-container [hasBackdrop]="false">
  <mat-drawer #drawer
              mode="over"
              position="end"
              [autoFocus]="false">
    <app-sidenav></app-sidenav>
  </mat-drawer>
  <mat-drawer-content (click)="backdropClicked()" style="min-height:900px;">
    <app-navbar></app-navbar>
    <app-chat-bubble></app-chat-bubble>
      <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
