import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

@Component({
    imports: [
        MatIconModule,
        MatButtonModule
    ],
    template: `
    <div class="d-flex w-100 flex-row justify-content-center align-items-center">
      <span >Se produjo un problema con tu conexión. Compruebe su conexión a internet e inténtalo de nuevo.</span>
      <button (click)="dismiss()" mat-icon-button>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  `
})
export class AlertFiledInternetConectionComponent implements OnInit {

  constructor(private snackBarRef: MatSnackBarRef<AlertFiledInternetConectionComponent>) { }

  ngOnInit(): void {
  }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }

}
