<div style="display: flex; position: fixed; width: 264px; flex-direction: column; padding: 20px; padding-top: 10px;">
  <div style="width: 100%;">
    <button (click)="toogle()" style="float: right;" type="button" mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <a mat-raised-button
     (click)="toogle()"
     style="margin-top: 10px;"
     color="primary"
     title="Get a Free Quote for your repair"
     routerLink="/new-order/brands" >
    FREE QUOTE
  </a>

  <mat-action-list style="align-content: center">
    <a (click)="toogle()" routerLink="/" class="drawer-item" mat-list-item title="Procell Repair Miami">
      <div class="row " >
        <div class="col col-2"> <mat-icon class="mr-2">home</mat-icon></div>
        <div class="col col-10 mt-1">  Home </div>
      </div>

    </a>

    <button (click)="toogle()" routerLink="contact-us"  type="button" class="drawer-item" mat-list-item>
      <div class="row " >
        <div class="col col-2"><mat-icon class="mr-2">contact_support</mat-icon></div>
        <div class="col col-10 mt-1">  Contact Us </div>
      </div>
    </button>

    @if (!(isLogin$|async)) {

      <a (click)="toogle()" title="Login" class="drawer-item" mat-list-item routerLink="/login" [routerLinkActive]="'active'">
        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">login</mat-icon></div>
          <div class="col col-10 mt-1">  Login </div>
        </div>
      </a>

      <a (click)="toogle()"  title="Sign In" class="drawer-item" mat-list-item routerLink="/register" [routerLinkActive]="'active'">

        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">app_registration</mat-icon></div>
          <div class="col col-10 mt-1">  Sign In </div>
        </div>
      </a>

}

    @if (isLogin$|async) {

      <a (click)="toogle()" class="drawer-item" mat-list-item routerLink="/orders" [routerLinkActive]="'active'">
        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">list</mat-icon></div>
          <div class="col col-10 mt-1"> My Orders </div>
        </div>
      </a>

<!--      <a (click)="toogle()" class="drawer-item" mat-list-item routerLink="/appointments" [routerLinkActive]="'active'">-->
<!--        <mat-icon class="mr-2">date_range</mat-icon>-->
<!--        <span >My Appointments</span>-->
<!--      </a>-->

      <a (click)="toogle()" class="drawer-item" mat-list-item routerLink="/payment-profiles" [routerLinkActive]="'active'">
        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">credit_card</mat-icon></div>
          <div class="col col-10 mt-1"> Payments Profiles </div>
        </div>
      </a>

      <a (click)="toogle()" class="drawer-item" mat-list-item routerLink="/manage-account/change-password" [routerLinkActive]="'active'">
        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">account_circle</mat-icon></div>
          <div class="col col-10 mt-1"> Manage Account </div>
        </div>
      </a>

      <a href="tel:+1 (786) 362-6254" title="Call us (786) 362-6254" (click)="toogle()" class="drawer-item" mat-list-item>
        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">phone</mat-icon></div>
          <div class="col col-10 mt-1"> +1 (786) 362-6254 </div>
        </div>
      </a>

<!--      <a *ngIf="localeId != 'en'" (click)="toogle()" routerLink="/" class="drawer-item" mat-list-item title="Procell Repair Miami">-->
<!--        <img alt="Flag US" class="flag mr-2" src="assets/img/us_44x44.png" loading="lazy">-->
<!--        <span >English</span>-->
<!--      </a>-->

<!--      <a *ngIf="localeId != 'es-US'" (click)="toogle()"  routerLink="/es" class="drawer-item" mat-list-item title="Procell Repair Miami">-->
<!--        <img alt="Flag ES" class="flag mr-2" src="assets/img/es_44x44.png" loading="lazy">-->
<!--        <span >Spanish</span>-->
<!--      </a>-->

      <a (click)="toogle()" class="drawer-item" mat-list-item routerLink="/login/logout" [routerLinkActive]="'active'">
        <div class="row " >
          <div class="col col-2"><mat-icon class="mr-2">logout</mat-icon></div>
          <div class="col col-10 mt-1"> Logout </div>
        </div>
      </a>

}
  </mat-action-list>
</div>
