import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import {Component, HostListener, Inject, LOCALE_ID, PLATFORM_ID} from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {AuthService} from "../../services/auth.service";
import {SidenavToggleService} from "../../services/sidenav-toggle.service";
import {Observable} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";

@Component({
    selector: 'app-navbar',
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterLink, RouterLinkActive, MatMenuModule, MatDividerModule],
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  isSticky = false;
  logo = '/assets/img/logo.svg';

  isLogin$: Observable<boolean>;
  isIOS: boolean;
  isMobile: boolean;
  visibleNavbar: boolean;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.updateIsSticky();
    this.updateLogo();
  }
  constructor(platform: Platform,
           //   @Inject(LOCALE_ID) public localeId: string,
              @Inject(PLATFORM_ID) private platformId: object|any,
              @Inject(DOCUMENT) private document: Document,
              public authService: AuthService,
              private breakpointObserver: BreakpointObserver,
              public sidenavToggleService: SidenavToggleService,
              public location: Location,
  ) {
    this.isLogin$ = this.authService.isAuthenticated$;
    this.isIOS = platform.IOS;
    this.isMobile = breakpointObserver.isMatched([Breakpoints.XSmall]);
    this.visibleNavbar = breakpointObserver.isMatched(['(min-width: 995px)']);
  }
  ngOnInit(): void {
    //console.log(this.localeId);
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(
      (result: BreakpointState) => this.isMobile = result.matches
    );

    this.breakpointObserver.observe(['(min-width: 995px)']).subscribe(
      (result: BreakpointState) => this.visibleNavbar = result.matches
    );

    this.updateIsSticky();
    this.updateLogo();

  }

  updateIsSticky(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isSticky = window.pageYOffset !== 0;
    }
  }

  updateLogo(): void {
    if (this.isSticky) {
      this.logo = '/assets/img/logo.svg';
    } else {
      this.logo = '/assets/img/logo-2.svg';
    }
  }

  back(): void {
    this.location.back();
  }

}
