import {ApplicationRef, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {BehaviorSubject, concat, filter, interval, map} from 'rxjs';
import { first } from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService {// 30 seconds

  private appUpdateSubject = new BehaviorSubject<boolean>(false);
  appUpdate$ = this.appUpdateSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: object|any, appRef: ApplicationRef, private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable));
      const every30Seconds$ = interval(30000);
      const every30SecondsOnceAppIsStable$ = concat(appIsStable$, every30Seconds$);

      every30SecondsOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());



      const updatesAvailable  = swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })));

      updatesAvailable.subscribe(event => {
        swUpdate.activateUpdate().then(() => this.appUpdateSubject.next(true));
      });

      swUpdate.unrecoverable.subscribe(event => {
        if (confirm(`An error occurred that we cannot recover from:\n${event.reason}\n\n` + 'Please reload the page.')) {
          if (isPlatformBrowser(this.platformId)) {
            document.location.reload();
          }
        }
      });
    }
  }
}
