import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Observable, of, throwError, EMPTY, Subject } from 'rxjs';
import { catchError, retryWhen, mergeMap, delay, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FORBIDDEN, INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from '../services/http-client.service';
import { BAD_REQUEST } from 'src/app/shared/services/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertFiledInternetConectionComponent } from '../components/alert/alert-filed-internet-conection/alert-filed-internet-conection.component';
import { AlertInternalErrorServerComponent } from '../components/alert/alert-internal-error-server/alert-internal-error-server.component';
import { AlertForbiddenUnauthorizedComponent } from '../components/alert/alert-forbidden-unauthorized/alert-forbidden-unauthorized.component';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private router: Router,
              private snackBar: MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error, request, next)));
  }

  private retryFailedHttpRequests(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      delayedRetry(1000),
      catchError((error) => {
        if (error.status === 0) {
          return EMPTY;
        }

        return this.errorHandler(error, request, next);
      }),
      shareReplay()
    );
  }

  private errorHandler(response: HttpEvent<any>|any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line: no-string-literal
    const httpErrorCode = response['status'];
    switch (httpErrorCode) {
      case BAD_REQUEST:
        break;
      case INTERNAL_SERVER_ERROR:
        this.snackBar.openFromComponent(AlertInternalErrorServerComponent, {
          duration: 3500,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });
        break;
      case NOT_FOUND:
        // if (this.router.url !== '/') { this.router.navigateByUrl(''); }
        break;
      case UNAUTHORIZED:
        if (this.authService.isLogin) {
          this.snackBar.openFromComponent(AlertForbiddenUnauthorizedComponent, {
            duration: 3500,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
          this.authService.logout();
          if (this.router.url !== '/') { this.router.navigateByUrl('/login'); }
        }
        break;
      case FORBIDDEN:
        if (this.authService.isLogin) {
          this.snackBar.openFromComponent(AlertForbiddenUnauthorizedComponent, {
            duration: 3500,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
          this.authService.logout();
          if (this.router.url !== '/') { this.router.navigateByUrl('/login'); }
        }
        break;
      default:
        console.log('Interceptor Http Error code', httpErrorCode);
        if (request.url === `${environment.apiBaseUrl}api/authenticated_user`) {
          return this.retryFailedHttpRequests(request, next);
        } else {
          this.snackBar.openFromComponent(AlertFiledInternetConectionComponent, {
            duration: -1,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }

        break;
    }

    throw response;
  }
}

const getErrorMessage = (maxRetry: number) => `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;
const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;
function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;

  return (src: Observable<any>) => src.pipe(
    retryWhen((errors: Observable<any>) =>
      errors.pipe(
        mergeMap(error => {
          if (retries-- > 0) {
            const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
            return of(error).pipe(delay(backoffTime));
          }

          return throwError(getErrorMessage(maxRetry));
        })
      )
    )
  );
}
