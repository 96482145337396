<footer class="position-relative">
  <div class="footer pt-lg-7 bg-section-dark footer-dark">
    <div class="container footer-container">
      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0 ml-4">
          <a href="https://procellrepair.com" title="Procell Repair, the best way to fix your device." >
            <img alt="Procell repair miami" title="Procell repair miami" width="48" height="48" loading="lazy" src="/assets/img/logo.svg">
          </a>
          <p class="mt-4 text-sm opacity-8 pr-lg-4 text-light">
            Procell Repair, Main Office , 1279 W Flagler St, Miami, FL 33135, USA.
          </p>
          <p class="mt-4 text-sm opacity-8 pr-lg-4 text-light" >All trademarks are properties of their respective holders. Procell Repair does not own or make claim to those trademarks used on this website in which it is not the holder.</p>

          <ul class="nav mt-4">

            <li class="nav-item mr-3">
              <a title="Contact us on Intagram to fix your device" class="nav-link pl-0" href="https://www.instagram.com/procellmiami" target="_blank" rel="noopener" >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                </svg>
              </a>
            </li>

            <li class="nav-item mr-3">
              <a title="Contact us on Face we are nearby"  class="nav-link pl-0" href="https://www.facebook.com/ProcellMiami/" target="_blank" rel="noopener " >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto pl-5 mb-5 mb-lg-0">
          <h3 class="h5 heading mb-3 text-light">Repairs</h3>
          <ul class="list-unstyled">
            <li><a  [routerLink]="['/repair', 'Apple']"  title="List Apple repair near me" >iPhone Repair</a></li>
            <li><a  [routerLink]="['/repair', 'Samsung']"  title="List Samsung repair near ">Samsung Repair</a></li>
            <li><a  [routerLink]="['/repair', 'ZTE']"  title="List Zte repair near me">Zte Repair</a></li>
            <li><a  [routerLink]="['/repair', 'Motorola']"  title="List Motorola repair near ">Motorola Repair</a></li>
            <li><a  [routerLink]="['/repair', 'Xiaomi']"  title="List Xiaomi repair near ">Xiaomi Repair</a></li>
            <li><a  [routerLink]="['/repair', 'LG']"  title="List LG repair near ">LG Repair</a></li>

          </ul>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 mb-5 pl-5 mb-lg-0">
          <h3 class="heading mb-3 h5 text-light" >Help and Support</h3>
          <ul class="list-unstyled">
            <li><a title="Repair Location in Miami" routerLink="/locations">Repair Locations in Miami</a></li>
            <li><a title="Our Services in Miami" routerLink="/services">Our Services in Miami</a></li>
            <li><a title="frequently asked questions" routerLink="/faq">FAQ</a></li>
            <li><a title="Device specs database list" routerLink="/device-specs" >Specs by brand</a></li>
            <li><a title="contact us" routerLink="/contact-us" >Contact US</a></li>
            <li><a title="warranty policy " routerLink="/policies/warranty-policy" >Warranty</a></li>
            <li><a title="refund policy " routerLink="/policies/refund-policy" >Refund Policy</a></li>

          </ul>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 mb-5 pl-5 mb-lg-0">
          <h3 class="heading mb-3 h5 text-light" >Information</h3>
          <ul class="list-unstyled">
            <li><a  href="https://news.procellrepair.com"  target="_blank" title="Last News">News</a></li>
            <li><a title="Terms and conditions " routerLink="/policies/terms-conditions" >Terms and Conditions</a></li>
            <li><a title="Privacy " routerLink="/policies/privacy-policy" >Privacy Policy</a></li>
            <li><a title="Terms of use " routerLink="/policies/terms-of-use" >Terms of Use</a></li>
            <li><a title="Cookie policy " routerLink="/policies/cookie-policy" >Cookies Policy</a></li>
          </ul>
        </div>
      </div>

      <hr class="divider divider-fade divider-dark my-4">

      <div class="row align-items-center justify-content-md-between pb-4 pl-3 text-light">
        <div class="col-md-6">
          <div class="copyright text-sm font-weight-bold text-center text-md-left">
            Copyright ©
            <a title="repair near to me , fix my device" href="https://procellrepair.com" class="font-weight-bold text-white" target="_blank" rel="noopener" >Procell</a>.
            All rights reserved
          </div>
        </div>

        <div class="col-md-6">
          <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
            <li class="nav-item">
              <a title="terms to fix your device" class="nav-link" routerLink="/policies/terms-of-use" >
                Terms
              </a>
            </li>
            <li class="nav-item">
              <a title="Privacy Policy"  class="nav-link" routerLink="/policies/privacy-policy" >
                Privacy
              </a>
            </li>
            <li class="nav-item">
              <a title="Cookies Policy" class="nav-link" routerLink="/policies/cookie-policy">
                Cookies
              </a>
            </li>
            <li class="nav-item">
              <a title="Sitemap" class="nav-link" routerLink="/sitemap">
                Sitemap
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
