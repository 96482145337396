import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-forbidden',
  standalone: true,
  template: `
    <span >Su sesión ha expirado, inicie sesión nuevamente.</span>
  `
})
export class AlertForbiddenUnauthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
