import { Component } from '@angular/core';
import {MatIconModule} from "@angular/material/icon";

@Component({
    selector: 'app-chat-bubble',
    imports: [
        MatIconModule
    ],
    templateUrl: './chat-bubble.component.html',
    styleUrl: './chat-bubble.component.scss'
})
export class ChatBubbleComponent {

}
