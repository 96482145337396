import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-internal',
  standalone: true,
  template: `
    <span >Ha ocurrido un error inesperado, intente nuevamente.</span>
  `,
})
export class AlertInternalErrorServerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
