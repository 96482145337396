import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "./shared/components/layout/layout.component";
import {inject, NgModule} from "@angular/core";
import {AuthGuard, canMatchUser} from "./shared/guards/auth.guard";
import {StaticServicesModule} from "./pages/static-services/static-services.module";
import {NotFoundComponentt} from "./pages/not-found/not-found.component";

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    title:'Miami\'s trusted repair specialists for iPhone|Procell Repair',
    children: [
      {
        path: 'new-order',
        loadChildren: () => import('./pages/repair-orders/repair-orders.module').then(m => m.RepairOrdersModule),
        title: 'New Order , Procell Phone Repair Miami | procellrepair.com'
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/user/user-login.module').then(m => m.UserLoginModule),
        title: 'Sign In, Procell Phone Repair Miami'
      },      {
        path: 'register',
        loadChildren: () => import('./pages/user-registration/user-registration.module').then(m => m.UserRegistrationModule),
        title: 'Sign Up, Procell Phone Repair Miami'
      },
      {
        path: 'repair',
        loadChildren: () => import('./pages/device-repairs/device-repairs.module').then(m => m.DeviceRepairsModule),
        title: 'Device Repair, Procell Phone Repair Miami'
      },
      {
        path: 'device-specs',
        loadChildren: () => import('./pages/device-specs/device-specs.module').then(m => m.DeviceSpecsModule),
        title: 'Device Specifications , Procell Phone Repair Miami'
      },
      {
        path: 'policies',
        loadChildren: () => import('./pages/static-pages/static-pages.module').then(m => m.StaticPagesModule),
        title: 'Our Policies , Procell Phone Repair Miami'
      },
      {
        path: 'services',
        loadChildren: () => import('./pages/static-services/static-services.module').then(m => m.StaticServicesModule),
        title: 'Our Services , Procell Phone Repair Miami'
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
        title: 'Contact Us , Procell Phone Repair Miami'
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsModule),
        title: 'Frequently Asked Questions, Procell Phone Repair Miami'
      },
      {
        path: 'sitemap',
        loadChildren: () => import('./pages/page-sitemap/page-sitemap.module').then(m => m.PageSitemapModule),
        title: 'Sitemap, Procell Phone Repair Miami | procellrepair.com'
      },{
        path: 'locations',
        loadChildren: () => import('./pages/locations-site/locations-site.module').then(m => m.LocationsSiteModule),
        title: 'Repair Locations Florida, Procell Phone Repair Miami'
      },
      {
        path: 'orders',
        loadChildren: () => import('./pages/user-orders/user-orders.module').then(m => m.UserOrdersModule),
        title: 'My Orders, Procell Phone Repair Miami',
        canMatch:  [() =>inject(AuthGuard).isAllowed],
        data: {type: 'order'}
      }, {
        path: 'payment-profiles',
        loadChildren: () => import('./pages/user-billing/user-billing.module').then(m => m.UserBillingModule),
        title: 'My Payment Methods, Procell Phone Repair Miami',
        canMatch:  [() =>inject(AuthGuard).isAllowed],
        data: {type: 'method'}
      },
      {
        path: 'manage-account',
        loadChildren: () => import('./pages/user-account/user-account.module').then(m => m.UserAccountModule),
        title: 'My Account, Procell Phone Repair Miami',
        //canMatch:  [canMatchUser],
        canMatch:  [() =>inject(AuthGuard).isAllowed]
      },
      {
        path: '',
        loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepageModule),
        title: 'Miami\'s trusted repair specialists for iPhone, Procell Repair'
      },
      {path: '404', component: NotFoundComponentt},
      { path: '**', component: NotFoundComponentt}
    ],


  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    // enableTracing: false,
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule],

})
export class AppRoutingModule { }
