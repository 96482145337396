import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../services/language.service';

@Injectable()
export class LocaleQueryInterceptor implements HttpInterceptor {

  lang: 'en' | 'es-US' | null = null;

  constructor(private languageService: LanguageService) {
    this.languageService.lang$.subscribe(lang => this.lang = lang);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //let params = request;
    /*params = params.append('locale', this.lang);*/
      request = request.clone({
        setParams: {locale: this.lang ?? 'es'}
      })
    return next.handle(request);
  }
}
